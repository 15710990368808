export const dashboardHeaderButtons = [
  {
    title: "dashboard.document",
    value: "document",
  },
  {
    title: "dashboard.quality",
    value: "quality",
    disabled: true,
  },
  {
    title: "dashboard.chat",
    value: "chat",
  },
];

export const addProjectFormItems = [
  {
    label: "dashboard.label1",
    name: "username",
    message: "dashboard.message1",
  },
  {
    label: "dashboard.label2",
    name: "botToken",
    message: "dashboard.message2",
  },
  {
    label: "dashboard.label3",
    name: "displayName",
    message: "dashboard.message3",
  },
];

export const paymentItems = [
  {}
]
