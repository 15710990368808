import React from 'react'

const ChatWidget = () => {
  return (
    <div>
      Chat
    </div>
  )
}

export default ChatWidget