import { isDocum } from "../const";

export const languageRu = {
  header: {
    about: "О нас",
    home: "Главная",
    pricing: "План",
    try_docum: "Демо",
  },
  home: {
    header: "Создайте помощника по обслуживанию клиентов с ",
    video: "https://youtu.be/2Rtp3mOlItE",
    text: " Мы поможем вам создать сотрудника службы поддержки клиентов с искусственным интеллектом с индивидуальными знаниями на основе предоставленной вами информации. Вы можете научить его чему угодно, и он сможет отвечать на вопросы клиентов.",
    "btn-text": "Старт",
    work: "Как это работает",
    about: "О нас",
    pricing: "План",
    intro: {
      title1: "Быстрый ответ!",
      text1: `${
        isDocum ? "Docum.ai" : "BlueJourney.pro"
      } может предоставлять немедленные ответы неограниченному количеству клиентов, когда они обращаются к вашему индивидуальному боту.`,
      title2: "Контроль!",
      text2: `Вы будете иметь полный контроль над качеством. Вы можете видеть чаты между клиентами и ботами.`,
      title3: "Доступный!",
      text3: `${
        isDocum ? "Docum.ai" : "BlueJourney.pro"
      } стоит в четыре раза меньше, чем наем сотрудника службы поддержки клиентов.`,
    },
    usage: {
      info1:
        "Для вашего бизнеса важно собирать данные о запросах клиентов и соответствующих ответах. Эта информация должна быть объединена в один файл. Если вам нужна помощь, наша команда всегда готова помочь.",
      info2: `Чтобы продолжить интеграцию модели ИИ в бота на ${
        isDocum ? "Docum.ai" : "BlueJourney.pro"
      }, предоставьте нам имя пользователя и токен бота. Как только мы получим необходимую информацию, мы включим модель ИИ, которая хорошо осведомлена о вашем бизнесе.`,
      info3:
        "После завершения процесса интеграции вы сможете взаимодействовать с чат-ботом и задавать вопросы, связанные с нашим бизнесом. Если бот не сможет ответить на какие-либо вопросы, они будут внимательно записаны. Мы рекомендуем предоставить ответы на эти вопросы и обновить бота снова.",
    },
    aboutPart: {
      title1: `${isDocum ? "Docum.ai" : "BlueJourney.pro"} — платформа для создания ИИ для обслуживания клиентов.`,

      text1: `${
        isDocum ? "Docum.ai" : "BlueJourney.pro"
      } — это платформа, созданная для того, чтобы помочь вам создавать решения для обслуживания клиентов на базе искусственного интеллекта. Независимо от того, являетесь ли вы предпринимателем, специалистом по поддержке клиентов или создателем контента, наша платформа дает вам возможность использовать искусственный интеллект и обеспечивать превосходный пользовательский опыт. \n Вместо того, чтобы полагаться на сотрудников службы поддержки клиентов, которые тратят 12 долларов в день на обслуживание 100 клиентов, каждый из которых задает примерно 10 вопросов, вы можете создать на нашей платформе бота, который справится с этой нагрузкой всего за 3 доллара!`,
      btn: "Начать",

      title2: "Вы можете тестировать и редактировать свои данные в реальном времени.",

      text2: `Загрузив документ в свой Telegram-бот, вы можете повысить качество его взаимодействия. Если ответы бота неудовлетворительны, просто отредактируйте загруженный документ для большей точности. Кроме того, вы можете удалить его или добавить дополнительные документы, чтобы улучшить производительность бота.`,

      title3: "Вы можете загрузить любой тип файла",

      text3:
        "Вы можете обучать свою модель ИИ, используя различные типы файлов: от текстовых документов до электронных таблиц. Помните, что качество данных существенно влияет на производительность ИИ, поэтому высококачественные данные обеспечивают более эффективные результаты ИИ для ваших нужд.",
    },
    pricingPart: {
      type1: "Базовый",
      price1: "Бесплатний",
      text1: "Вы можете протестировать модель AI с вашими пользовательскими данными",
      includes1: {
        line1: "Загрузите один документ (максимум 2 МБ)",
        line2: "Чат с пользовательскими знаниями на основе ИИ",
        line3: "20 Вопросов",
      },
      type2: "Премиум",
      price2: "19$",
      price_duration2: "/ месяц",
      text2: "+ Также вы должны заплатить комиссию AI.",
      includes2: {
        line1: "Загрузите 10 документов (не более 10 МБ)",
        line2: "Редактировать документ",
        line3: "Интегрирован один бот Telegram",
        line4: "Служба поддержки",
        line5: "Проверка данных в режиме реального времени",
      },
      type3: "Предприятилский",
      price3: "19$",
      price_duration3: "/ месяц",
      text3: "+ Также вы должны заплатить комиссию AI.",
      includes3: {
        line1: "Безлимитные документы",
        line2: "10 Telegram ботов",
        line3: "Поддержка аналитики данных",
        line4: "Проверка данных в режиме реального времени",
      },
    },
    footer: {
      title: "Нам доверяют 10+ компаний",
      description: "Никаких долгосрочных контрактов. Никаких уловов. Простой.",
      text: "Начните 30-дневную бесплатную пробную версию. Отменить в любое время",
      demo: "Посмотреть демо",
      start: "Начать",
      docum: `${isDocum ? "Docum.ai" : "BlueJourney.pro"}`,
      "text-down": `${
        isDocum ? "Docum.ai" : "BlueJourney.pro"
      } — это искусственный интеллект, который автоматизирует ваш бизнес.`,
      overview: " Обзор",
      features: "Функции",
      "pricing-down": "План",
      help: "Помощь",
      "demo-down": "Демо",
    },
  },
  about: {
    title: "Мы создали инструмент ИИ, который облегчает жизнь людей",
    text: `Чтобы быть такой компанией, какой нас хотят видеть наши клиенты, нужна группа энергичных увлеченных людей. Познакомьтесь с лидерами ${
      isDocum ? "Docum.ai" : "BlueJourney.pro"
    }.`,
    about: "О проекте",

    aboutPart: {
      title1: `Миссия ${isDocum ? "Docum.ai" : "BlueJourney.pro"}`,
      text1:
        "В настоящее время у нас есть только текстовый инструмент обслуживания клиентов, но по мере того, как искусственный интеллект развивается день ото дня, мы также запускаем голосовые и видеосервисы. Это еще не все, в ближайшие 2 месяца мы планируем запрограммировать ИИ также на выполнение действий, что означает много возможностей для вашего бизнеса. Вы просто создаете свой бренд, а наш инструмент искусственного интеллекта помогает вашим клиентам понять и использовать вашу услугу или продукт.",
      title2: `Некоторые факты о ${isDocum ? "Docum.ai" : "BlueJourney.pro"}`,
      text2:
        "Мы новая компания, расположенная в Узбекистане и созданная в начале 2023 года. Наша текущая команда состоит из 20 человек, которые горят желанием увидеть весь мир. Мы открыты для понимания потребностей наших клиентов и хотели бы внести необходимые изменения, в конце концов, мы должны делать то, что хотят наши клиенты, а не то, что хотим мы.",
    },

    aboutUs: "О нас",
    names: {
      name1: "Samandar. T.",
      who1: "CEO",
      name2: "Shohruxmirzo. T.",
      who2: "CTO",
      name3: "Asror. N.",
      who3: "Programmer",
      name4: "Mirsaid. B.",
      who4: "UX/UI",
      name5: "Vosilxo'ja. N.",
      who5: "SMM manager",
      name6: "Avazbek. S.",
      who6: "SWE",
    },

    projects: "Наши проекты",
    project: {
      title1: "Simyog’och",
      text1:
        "Siyog’och — это идеальное приложение и веб-сайт, который расширяет возможности как соискателей, так и работодателей. Благодаря удобному интерфейсу найти идеальную работу или нанять идеального кандидата еще никогда не было так просто.",
      title2: "Qunduzbobo",
      text2:
        "Qunduzbobo — это новаторский веб-сайт и приложение, основанное на искусственном интеллекте, которое оживляет личности. Участвуйте в динамичных беседах, исследуйте творческие занятия, такие как кодирование, письмо, наука и создание изображений, и все это в одном месте.",
    },
  },

  dashboard: {
    home: "Главная",
    help: "Помощь",
    document: "Документ",
    quality: "Качества",
    chat: "Чат",
    label1: "Имя пользователя бота*",
    name1: "Имя пользователя бота*",
    message1: "Пожалуйста, введите имя пользователя бота",
    label2: "Токен бота*",
    name2: "Токен бота",
    message2: "Пожалуйста, введите токен бота",
    label3: "Имя чат-бота*",
    name3: "Отображаемое имя",
    message3: "Пожалуйста, введите имя чат-бота*",
    create: "Новый бот",
    create1: "Создайте своего чат-бота",
    text: "Вы можете создать Телеграм бот в @BotFather.",
    guide: "Видео инструкция",
    next: "Следующий",
    save: "Сохранить",
    edit: "Редактировать бот",
    logout: "Выйти",
    name4: "Имя пользователя бота:",
    user: "Пользователи",
    activation: "Активные боты",
    balance: "Баланс:",
    balance1: "Баланс:",
    bot: "Боты",
    users: "Пользователи",
    yourBots: "Вашы боты",
    tryData: "Попробовать эти данные",
    addDoc: "Новый документ",
    upload: "Пожалуйста, загрузите ваши документы",
    instraction: "Посмотреть инструкцию",
    uploadBtn: "Загрузить",
    uploadContent: "Нажмите или перетащите документ в эту область, чтобы загрузить",
    maxFile: "Максимальный размер файла",
    mb: "MB!",
    useTranslation: "Использовать перевод",
    hovertranslation:
      "Используйте это для неанглоязычных документов для перевода и обучения, чтобы получить наилучшие результаты.",
    selection: "Выберите пользователя для просмотра сообщений",
    keywords: "Ключевые слова",
    newKeyword: "Новый",
    keywordHelp: "*** Пожалуйста, добавляйте только одно ключевое слово на английском языке для каждого файла. ***",
    scriptCodeLabel: "Код",
  },

  sign: {
    sign: "Войти",
    signUp: "Зарегистрироваться",
    text: "Интеграция ИИ в ваш бизнес открывает целый мир возможностей",
  },
};
