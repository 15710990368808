export const photoData = [
  {
    img: require("src/assets/img/news/details_1.png"),
  },
  {
    img: require("src/assets/img/news/details_1.png"),
  },
  {
    img: require("src/assets/img/news/details_1.png"),
  },
];
