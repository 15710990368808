export const cursorTypist = {
  show: true,
  blink: true,
  element: "",
  hideWhenDone: true,
  hideWhenDoneDelay: 250,
};

export const isMobile = window.innerWidth < 600;

export const hostname = window.location.hostname;

export const isDocum = hostname === "docum.ai";
