export const widgetThemeColors = {
  light: {
    primary: "#007fff",
    white: "#ffffff",
    text_primary: "#495057",
    text_placeholder: "#49505770",
    bg_primary: "#f2f2f7",
    bg_secondary: "#f3f6f9",
  },
  dark: {
    primary: "#26425F",
    white: "#212529",
    text_primary: "#ffffff",
    text_placeholder: "#ffffff70",
    bg_primary: "#1A1D21",
    bg_secondary: "#2F343A",
  },
};
