export const CrownSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M45.7234 14.2848C45.385 13.9898 44.9666 13.802 44.5212 13.7452C44.0759 13.6885 43.6237 13.7653 43.2222 13.9661L33.8078 18.6536L25.9478 5.59796C25.7457 5.26295 25.4606 4.98582 25.1199 4.79345C24.7792 4.60108 24.3946 4.5 24.0034 4.5C23.6122 4.5 23.2276 4.60108 22.8869 4.79345C22.5463 4.98582 22.2611 5.26295 22.059 5.59796L14.199 18.6592L4.78841 13.9717C4.3878 13.7728 3.93735 13.6965 3.49359 13.7527C3.04983 13.8088 2.63253 13.9947 2.29405 14.2871C1.95557 14.5795 1.711 14.9654 1.59102 15.3963C1.47104 15.8272 1.48101 16.2839 1.61966 16.7092L8.55716 37.9642C8.62691 38.1777 8.74373 38.3729 8.899 38.5352C9.05426 38.6976 9.24401 38.823 9.45422 38.9021C9.66444 38.9813 9.88975 39.0123 10.1135 38.9928C10.3373 38.9732 10.5539 38.9036 10.7472 38.7892C10.794 38.7611 15.5884 35.9992 24.0034 35.9992C32.4184 35.9992 37.2128 38.7611 37.2559 38.7873C37.4493 38.9029 37.6662 38.9734 37.8905 38.9936C38.1149 39.0138 38.3409 38.9833 38.5518 38.9042C38.7628 38.8251 38.9532 38.6996 39.1089 38.5368C39.2647 38.3741 39.3818 38.1784 39.4515 37.9642L46.389 16.7148C46.5317 16.2895 46.5445 15.8313 46.426 15.3985C46.3075 14.9658 46.0629 14.5781 45.7234 14.2848ZM32.9734 29.3992C32.9122 29.7462 32.7308 30.0606 32.4609 30.2872C32.1911 30.5138 31.8501 30.6382 31.4978 30.6386C31.4098 30.6385 31.322 30.6309 31.2353 30.6161C26.4441 29.7936 21.5477 29.7936 16.7565 30.6161C16.5625 30.6503 16.3636 30.646 16.1713 30.6034C15.9789 30.5607 15.7969 30.4806 15.6355 30.3676C15.3095 30.1394 15.0875 29.7911 15.0184 29.3992C14.9493 29.0074 15.0387 28.6041 15.2669 28.2781C15.4951 27.9522 15.8434 27.7302 16.2353 27.6611C21.3714 26.7786 26.6204 26.7786 31.7565 27.6611C31.9513 27.6944 32.1375 27.7659 32.3045 27.8713C32.4715 27.9768 32.6161 28.1143 32.7298 28.2758C32.8436 28.4373 32.9243 28.6197 32.9674 28.8124C33.0105 29.0052 33.0151 29.2046 32.9809 29.3992H32.9734Z"
      fill="#FFC700"
    />
  </svg>
);

export const UzcardSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="34"
    viewBox="0 0 53 34"
    fill="none"
  >
    <g clip-path="url(#clip0_1733_3255)">
      <path
        d="M29.1828 3.11719H16.7812V25.4007H29.1828V3.11719Z"
        fill="#FF5F00"
      />
      <path
        d="M17.5697 14.2624C17.5697 9.93174 19.5775 5.79788 22.9634 3.12071C16.8216 -1.72181 7.88462 -0.658817 3.0421 5.52229C-1.76105 11.664 -0.698061 20.5617 5.48304 25.4042C10.6405 29.4593 17.8452 29.4593 23.0027 25.4042C19.5775 22.727 17.5697 18.5932 17.5697 14.2624Z"
        fill="#EB001B"
      />
      <path
        d="M45.9134 14.2624C45.9134 22.0971 39.5748 28.4357 31.7402 28.4357C28.5512 28.4357 25.4803 27.3727 23 25.4042C29.1417 20.5617 30.2047 11.664 25.3622 5.48292C24.6535 4.61677 23.8661 3.79 23 3.12071C29.1417 -1.72181 38.0787 -0.658817 42.8819 5.52228C44.8504 8.0026 45.9134 11.0735 45.9134 14.2624Z"
        fill="#F79E1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1733_3255">
        <rect width="53" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CardAddingSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
  >
    <path
      d="M32.5 6C27.3577 6 22.3309 7.52487 18.0552 10.3818C13.7795 13.2387 10.447 17.2994 8.47914 22.0502C6.51127 26.8011 5.99638 32.0288 6.99959 37.0723C8.00281 42.1159 10.4791 46.7486 14.1152 50.3848C17.7514 54.0209 22.3842 56.4972 27.4277 57.5004C32.4712 58.5036 37.6989 57.9887 42.4498 56.0209C47.2007 54.053 51.2613 50.7205 54.1182 46.4448C56.9751 42.1691 58.5 37.1423 58.5 32C58.4914 25.107 55.7494 18.4988 50.8753 13.6247C46.0012 8.75064 39.393 6.0086 32.5 6ZM42.5 34H34.5V42C34.5 42.5304 34.2893 43.0391 33.9142 43.4142C33.5392 43.7893 33.0304 44 32.5 44C31.9696 44 31.4609 43.7893 31.0858 43.4142C30.7107 43.0391 30.5 42.5304 30.5 42V34H22.5C21.9696 34 21.4609 33.7893 21.0858 33.4142C20.7107 33.0391 20.5 32.5304 20.5 32C20.5 31.4696 20.7107 30.9609 21.0858 30.5858C21.4609 30.2107 21.9696 30 22.5 30H30.5V22C30.5 21.4696 30.7107 20.9609 31.0858 20.5858C31.4609 20.2107 31.9696 20 32.5 20C33.0304 20 33.5392 20.2107 33.9142 20.5858C34.2893 20.9609 34.5 21.4696 34.5 22V30H42.5C43.0304 30 43.5392 30.2107 43.9142 30.5858C44.2893 30.9609 44.5 31.4696 44.5 32C44.5 32.5304 44.2893 33.0391 43.9142 33.4142C43.5392 33.7893 43.0304 34 42.5 34Z"
      fill="white"
    />
  </svg>
);

export const PricingLowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="46"
    viewBox="0 0 27 46"
    fill="none"
  >
    <path
      d="M8.86561 43.4141L12.6937 28.2969H2.07059C0.576781 28.2969 0.843132 27.069 1.16303 26.4551C6.0357 18.7114 16.0531 2.81866 17.1415 1.19681C18.2298 -0.425039 19.6323 1.07376 19.0888 2.7433L15.3009 17.9223H25.7797C27.2715 17.9223 26.8897 19.3865 26.5123 20.1186C22.1891 26.9837 13.133 41.3863 11.4948 44.0768C10.0918 46.381 8.56997 44.3476 8.86561 43.4141Z"
      fill="#664DFF"
    />
  </svg>
);

export const HomeGridSvg = () => (
  <svg width="1425" height="880" viewBox="0 0 1425 880" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1579_3313" maskUnits="userSpaceOnUse" x="-15" y="0" width="1440" height="880">
<line x1="-15" y1="79.5" x2="1425" y2="79.5" stroke="white"/>
<line x1="-15" y1="279.5" x2="1425" y2="279.5" stroke="white"/>
<line x1="-15" y1="479.5" x2="1425" y2="479.5" stroke="white"/>
<line x1="-15" y1="779.5" x2="1425" y2="779.5" stroke="white"/>
<line x1="-15" y1="179.5" x2="1425" y2="179.5" stroke="white"/>
<line x1="-15" y1="379.5" x2="1425" y2="379.5" stroke="white"/>
<line x1="-15" y1="679.5" x2="1425" y2="679.5" stroke="white"/>
<line x1="-15" y1="579.5" x2="1425" y2="579.5" stroke="white"/>
<line x1="-15" y1="879.5" x2="1425" y2="879.5" stroke="white"/>
<line x1="785.5" y1="2.16073e-08" x2="785.5" y2="722" stroke="white"/>
<line x1="585.5" y1="2.16073e-08" x2="585.5" y2="722" stroke="white"/>
<line x1="385.5" y1="2.16073e-08" x2="385.5" y2="722" stroke="white"/>
<line x1="85.5" y1="2.16073e-08" x2="85.5" y2="722" stroke="white"/>
<line x1="685.5" y1="2.16073e-08" x2="685.5" y2="722" stroke="white"/>
<line x1="485.5" y1="2.16073e-08" x2="485.5" y2="722" stroke="white"/>
<line x1="185.5" y1="2.16073e-08" x2="185.5" y2="722" stroke="white"/>
<line x1="285.5" y1="2.16073e-08" x2="285.5" y2="722" stroke="white"/>
</mask>
<g mask="url(#mask0_1579_3313)">
<path opacity="0.3" d="M-180 -282H1068.72L1235 647L-180 621.941V-282Z" fill="url(#paint0_radial_1579_3313)"/>
</g>
<defs>
<radialGradient id="paint0_radial_1579_3313" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(444.36 282.963) rotate(88.3648) scale(384.746 531.289)">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
</defs>
</svg>

)
