export const memberpicData = [
  {
    title: "about.names.name1",
    description: "about.names.who1",
    img: require("src/assets/img/about/Samandar.png"),
  },
  {
    title: "about.names.name2",
    description: "about.names.who2",
    img: require("src/assets/img/about/Shohruxmirzo.png"),
  },
  {
    title: "about.names.name6",
    description: "about.names.who6",
    img: require("src/assets/img/about/Avazbek.png"),
  },
  {
    title: "about.names.name3",
    description: "about.names.who3",
    img: require("src/assets/img/about/Asror.png"),
  },
  {
    title: "about.names.name4",
    description: "about.names.who4",
    img: require("src/assets/img/about/Mirsaid.png"),
  },
  {
    title: "about.names.name5",
    description: "about.names.who5",
    img: require("src/assets/img/about/Vosilxoja.png"),
  },
];

export const aboutProData = [
  {
    title: "about.aboutPart.title1",
    text: "about.aboutPart.text1",
    img: require("src/assets/img/about/projectMission.jpg"),
  },
  {
    title: "about.aboutPart.title2",
    text: "about.aboutPart.text2",
    img: require("src/assets/img/about/projectFact.jpg"),
  },
];

export const ProjectsPartData = [
  {
    link: "https://simyogoch.uz/",
    title: "about.project.title1",
    text: "about.project.text1",
    img: require("src/assets/img/about/simyogoch.png"),
  },
  {
    link: "https://qunduzbobo.uz/login",
    title: "about.project.title2",
    text: "about.project.text2",
    img: require("src/assets/img/about/qunduzbobo.png"),
  },
];
