export const webinarData = [
  {
    title:
      "The Beatles – is home to the capital, London, a globally influential centre of",
    date: "May 23, 2023",
    text: "The United Kingdom, made up of England, Scotland, Wales and Northern Ireland, is an island nation in northwestern Europe.",
    img: require("src/assets/img/news/webinar_1.png"),
  },
  {
    title:
      "The U.S. is a country of 50 states covering a vast swath of North America, with Alaska in the northwest and Hawaii",
    date: "May 23, 2023",
    text: "DC. Midwestern metropolis Chicago is known for influential architecture and on the west coast, Los Angeles' Hollywood is famed for filmmaking. ― Google",
    img: require("src/assets/img/news/webinar_2.png"),
  },
];

export const documData = [
  {
    title: "The Beatles – is home to the capital, London, ",
    date: "May 23, 2023",
    text: "The United Kingdom, made up of England, Scotland, Wales",
    img: require("src/assets/img/news/docum_1.png"),
  },
  {
    title: "The U.S. is a country of 50",
    date: "May 23, 2023",
    text: "DC. Midwestern metropolis Chicago is known for and on the west coast, Los Angeles' ",
    img: require("src/assets/img/news/docum_2.png"),
  },
  {
    title: "Major Atlantic coast cities are New York, a global finance",
    date: "May 23, 2023",
    text: "The U.S. is a country of 50 states covering a vast swath of North America, with Alaska in the northwest ",
    img: require("src/assets/img/news/docum_3.png"),
  },
  {
    title: "Major Atlantic coast cities are New York, a global finance",
    date: "May 23, 2023",
    text: "The U.S. is a country of 50 states covering a vast swath of North America, with Alaska in the northwest ",
    img: require("src/assets/img/news/docum_1.png"),
  },
  {
    title: "Major Atlantic coast cities are New York, a global finance",
    date: "May 23, 2023",
    text: "The U.S. is a country of 50 states covering a vast swath of North America, with Alaska in the northwest ",
    img: require("src/assets/img/news/docum_2.png"),
  },
  {
    title: "Major Atlantic coast cities are New York, a global finance",
    date: "May 23, 2023",
    text: "The U.S. is a country of 50 states covering a vast swath of North America, with Alaska in the northwest ",
    img: require("src/assets/img/news/docum_3.png"),
  },
];
